import {Outlet} from 'react-router-dom'
import Footer from "../../Footer";
import "./index.scss";
import NavBar from "../../NavBar";
const LayoutOrder = () => {
    return (
        <>
            <div className="container">
                <NavBar/>
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
}
export default LayoutOrder