const Sprite =_=>(
<svg width="192" height="34" viewBox="0 0 192 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.77" clipPath="url(#clip0_22_192)">
        <a href="https://pinterest.com/" target="blank">
            <g>
                <rect x="159" y="1" width="32" height="32" rx="16" fill="black" fillOpacity="0.01"
                      stroke="#CC212C" strokeWidth="2"/>
                <rect x="170" y="11" width="10" height="12" fill="url(#pattern0)"/>
            </g>
        </a>
        <a href="https://dribbble.com" target="blank">
            <g>
                <rect x="120" y="1" width="32" height="32" rx="16" fill="black" fillOpacity="0.01"
                      stroke="#D97AB0" strokeWidth="2"/>
                <rect x="130" y="11" width="12" height="12" fill="url(#pattern1)"/>
            </g>
        </a>
        <a href="https://twitter.com/" target="blank">
            <g>
                <rect x="80" y="1" width="32" height="32" rx="16" fill="black" fillOpacity="0.01"
                      stroke="#45B0E3" strokeWidth="2"/>
                <rect x="91" y="11" width="9" height="12" fill="url(#pattern2)"/>
            </g>
        </a>
        <a href="https://facebook.com/" target="blank">
            <g>
                <rect x="41" y="1" width="32" height="32" rx="16" fill="black" fillOpacity="0.01"
                      stroke="#39599F" strokeWidth="2"/>
                <rect x="54" y="11" width="6" height="12" fill="url(#pattern3)"/>
            </g>
        </a>
        <a href="https://uk.wikipedia.org/wiki/RSS" target="blank">
            <g>
                <rect x="1" y="1" width="32" height="32" rx="16" fill="black" fillOpacity="0.01"
                      stroke="#F8902E" strokeWidth="2"/>
                <rect x="12" y="12" width="10" height="10" fill="url(#pattern4)"/>
            </g>
        </a>
    </g>
    <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#image0_22_192" transform="scale(0.1 0.0833333)"/>
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#image1_22_192" transform="scale(0.0833333)"/>
        </pattern>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#image2_22_192" transform="scale(0.111111 0.0833333)"/>
        </pattern>
        <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#image3_22_192" transform="scale(0.166667 0.0833333)"/>
        </pattern>
        <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#image4_22_192" transform="scale(0.1)"/>
        </pattern>
        <clipPath id="clip0_22_192">
            <rect width="192" height="34" fill="white"/>
        </clipPath>
        <clipPath id="clip1_22_192">
            <rect width="34" height="34" fill="white" transform="translate(158)"/>
        </clipPath>
        <clipPath id="clip2_22_192">
            <rect width="34" height="34" fill="white" transform="translate(119)"/>
        </clipPath>
        <clipPath id="clip3_22_192">
            <rect width="34" height="34" fill="white" transform="translate(79)"/>
        </clipPath>
        <clipPath id="clip4_22_192">
            <rect width="34" height="34" fill="white" transform="translate(40)"/>
        </clipPath>
        <clipPath id="clip5_22_192">
            <rect width="34" height="34" fill="white"/>
        </clipPath>
        <image id="image0_22_192" width="10" height="12"
               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAAt0lEQVQYlW2QXQ3CQBCEvzYIQAJ9JwEJOIA6AAlVQKoAHAAOigIQQEIdFAecA8gks8mRMk+7Nz97u8WjmgNMgT2wdR3ogBboJyZuwBI4A1eL1jZugLoEThbtgIvru/vGpoNGf0zUwNuEBEfX4ind9E4i638gYfI/XiZSJgxzklBjZsDKjzJILOgSQiehNi2ARZbyBAZPkqmJPwqRqLuJVLIWqtTHwWM7kSJGyBMF3fEvcmGMGgP4AiQeKmbjHB1AAAAAAElFTkSuQmCC"/>
        <image id="image1_22_192" width="12" height="12"
               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAA6UlEQVQokU2S0bEBURBED7UBbAhCIAJksDIgA379KBGQARmQASLgfb6/DUEI6tC36k7V1O7O9nT33LmD/+2VxAjYAR3QpvYGBOyB3kKTH2PgVgEF/QFTYBmSOfAahvkWoMU7MAOO+T6GSMyoiY22Aiv9TF2yriLZNZHro7AMyFhnhkUsqtA1YW8rW30YC/M4abTDvHgKg+SmAtjk4KaWsEFZC8YBuERllZoEZb63Dfqz+xTfBXAOmSr+N68uziE9FeeQRXAJ1bRX9jNxaOUFObQsPh/V9k2VxPRl0y+7q6vxHTBArf2uBvAB+tI7+oYeiyMAAAAASUVORK5CYII="/>
        <image id="image2_22_192" width="9" height="12"
               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAAdklEQVQYlY2RUQ2AMAwFD4IApAwF8IMOsICj4QAH4IBaIBhAAikZyVK2hPtZutzat63ol3MHHCBAQ4IyCITV5aSYOiVVph6ALqovwFtpTDRq7bgktpMHDrtnpRnY/gSP0eCij7maG1lEg0/hRA6n0vsdnywPIDd20xTROZ0meQAAAABJRU5ErkJggg=="/>
        <image id="image3_22_192" width="6" height="12"
               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAMCAYAAABBV8wuAAAAUklEQVQYlWO0jJzPwMDAYMDAwLCegYFBgQEKmKB0PbIgCLBAaQEksUQGBoYHIB0OaBIPYDr2IxsB48PswAAgCUYGBoYDSDIgPiNeHTSVYGBgAADjAwetkEKPggAAAABJRU5ErkJggg=="/>
        <image id="image4_22_192" width="10" height="10"
               href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAqUlEQVQYlW2QUQ3CQBAFp6QCkFAMkEoABQQJOAAJKKA4wAFBAaAAHIAM/iDT3jb7wSaX6929nX2v1aebfxnqCdyAI/Aud2NN0ncLbIEXcPgnrIAlsEskGx7ANAuvwKqMngH7NMG3UbgoBC9dJ2CTxL0NhRIMYdnkSM9BFtKYOuiKzsWXQn0brAE6iaLDyzo1ufxV/bku6Cgp0hQZ8BJeJZrWiv2eggzegR+KQySg1Lx70wAAAABJRU5ErkJggg=="/>
    </defs>
</svg>
)

export default Sprite