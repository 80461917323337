import "./index.scss"
import Table from 'react-bootstrap/Table';
import Button from "../Button";
import MyForm from "./MyForm";
//MyForm
import {
    setCounterOrder,
    increase,
    decrease,
    setOpenModal,
    deleteProduct,
    activeModal,
} from "../../my-storage/mySlice";

import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

const Order = ({closeModal}) => {

    const dispatch = useDispatch()
    const setActiveModal = bool => dispatch(activeModal({bool}))

    const clickOpenModal = stand => {
        dispatch(setOpenModal({
            modalTitleText: 'Delete',
            modalContentText: 'Are you sure you want to remove an item from your shopping cart?',
            modalBtnCloseActive: true,
            functionOnClick: () => {
                setTimeout(_ => {
                    dispatch(deleteProduct(stand.article))
                    dispatch(setCounterOrder(stand))
                }, 500)
                closeModal()
            }
        }))
        setActiveModal(true)
    }

    let stands = []                      //В navigate в YourSelect(загальний компонент для фаворита та кошика) в кошику
    const location = useLocation().state //другим параметром передаємо стейт куди вкладаємо наш масив замовлень для отримання тут
    //const navigate = useNavigate()
    //console.log(location)
        const A = useSelector(state => state.my.counterProduct)//якщо оформляємо всі замовлення кошика
        const B = useSelector(state => location && state.my.counterProduct.find(_ => _.article === location[0].article)) //або тільки один
        location?.length > 1 ? stands = A : stands = [B]

        useEffect(_ => {
           //!location && navigate("/")       //можна відразу поставити переадресацію, якщо прийшли не з кошика
            dispatch(setCounterOrder(stands))
        }, [dispatch])

        const counterOrder = useSelector(state => state.my.order.counterOrder)//являє собою масив товарів
    // який готується для відправки на сервер (обрізана версія counterProduct)
        const locCount = obg => counterOrder.find(_ => _.article === obg.article && _.count)
        const locTotal = obg => counterOrder.find(_ => _.article === obg.article && _.price)
        const globCount = _ => counterOrder.reduce((a, b) => a + b.count, 0)
        const globTotal = _ => counterOrder.reduce((a, b) => a + b.price, 0)
        const deleteLoc = stand => {
            clickOpenModal(stand)
        }

        if (Boolean(stands[0])) {
            return (
                <div className="order-wrap">
                    <div className="my-table">
                        <p className="main--trek">Checkout</p>
                        <div className="table--trek">
                            <span>Goods for clearance</span>
                        </div>
                        <hr/>
                        <Table striped bordered hover variant="dark">
                            <thead>
                            <tr>
                                <th>Article</th>
                                <th>Card Title</th>
                                <th>Price</th>
                                <th>Count</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {stands.map(stand => {
                                return (<tr key={stand.article}>
                                    <td className="td td-article">{stand.article}</td>
                                    <td className="td td-title">{stand.cardTitle}</td>
                                    <td className="td td-price">{locTotal(stand)?.price}</td>
                                    <td className="td td-count">
                                        <Button
                                            btnText={"-"}
                                            btnClass="btn-d btn-order"
                                            functionOnClick={_ => {
                                                console.log(stand)
                                                console.log(locCount(stand))
                                                Boolean(locCount(stand)?.count - 1) ?
                                                    dispatch(decrease({obj: stand, count: locCount(stand)}))
                                                    : deleteLoc(stand)
                                            }}
                                        />
                                        <span>{locCount(stand)?.count}</span>
                                        <Button
                                            btnText="+"
                                            btnClass="btn-d btn-order"
                                            functionOnClick={_ => dispatch(increase(stand))}
                                        />
                                    </td>
                                    <td className="td td-delete">
                                        <span onClick={_ => clickOpenModal(stand)}>&times;</span>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className={"order-total"} colSpan="2">Total payable:</td>
                                <td>{globTotal()}</td>
                                <td>{globCount()}</td>
                                <td></td>
                            </tr>
                            </tfoot>
                        </Table>
                    </div>
                    <hr/>
                    <MyForm
                        counterOrder={counterOrder}
                        closeModal={closeModal}
                    />
                </div>
            )
        } else {
            return <div className="order-wrap">
                <h2>Сheckout page</h2>
                <p>But you don't have items to checkout yet</p>
                <p>You need to first select a product, then go to the shopping cart and place an order.
                    If the product is already selected, just go to the <NavLink to="/cart">shopping cart</NavLink> for checkout.</p>
                <p><NavLink to="/guns" className="btn-d btn-nav-link btn-width-50">Guns</NavLink></p>
                <p><NavLink to="/" className="btn-d btn-nav-link btn-width-50">Home</NavLink></p>
            </div>
        }
    }

Order.propTypes = {
    mySelect: PropTypes.object,
    closeModal: PropTypes.func,
}
export default Order

const work=()=>{

}