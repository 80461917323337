import "./basket.scss"
import PropTypes from "prop-types";
const Basket =({color, width, height, openBasket})=> {
    return (
        <svg className="icon-basket" onClick={openBasket} width={width} height={height} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" fill="none" >
                <path
                    d="M15.244,5.084 L13.316,5.084 L12.281,1.447 C12.13,1.13 11.755,1.002 11.443,1.161 L11.372,1.197 C11.061,1.357 10.932,1.743 11.083,2.06 L11.824,5.083 L5.17,5.083 L5.938,2.064 C6.092,1.75 5.965,1.362 5.657,1.199 L5.587,1.163 C5.277,1.001 4.899,1.125 4.745,1.44 L3.672,5.083 L1.782,5.083 C1.382,5.083 1.057,5.385 1.057,5.755 C1.057,5.755 1.381,6.975 1.782,6.975 L2.418,6.975 L2.867,13.652 C2.867,13.652 2.919,14.961 4.98,14.961 L12.001,14.961 C14.146,14.961 14.116,13.625 14.116,13.625 L14.479,6.976 L15.245,6.976 C15.645,6.976 15.97,5.756 15.97,5.756 C15.969,5.386 15.644,5.084 15.244,5.084 L15.244,5.084 Z M4.031,7.031 L2.969,7.031 L2.969,5.969 L4.031,5.969 L4.031,7.031 L4.031,7.031 Z M6.062,12.742 C6.062,13.092 5.811,13.375 5.503,13.375 L5.48,13.375 C5.169,13.375 4.919,13.092 4.919,12.742 L4.919,8.413 C4.919,8.063 5.169,7.781 5.48,7.781 L5.503,7.781 C5.811,7.781 6.062,8.063 6.062,8.413 L6.062,12.742 L6.062,12.742 Z M9.06,12.547 C9.06,12.881 8.823,13.152 8.531,13.152 L8.51,13.152 C8.217,13.152 7.981,12.881 7.981,12.547 L7.981,8.448 C7.981,8.114 8.217,7.844 8.51,7.844 L8.531,7.844 C8.823,7.844 9.06,8.115 9.06,8.448 L9.06,12.547 L9.06,12.547 Z M12.028,12.639 C12.028,12.977 11.783,13.25 11.482,13.25 L11.46,13.25 C11.158,13.25 10.915,12.977 10.915,12.639 L10.915,8.483 C10.915,8.145 11.158,7.872 11.46,7.872 L11.482,7.872 C11.783,7.872 12.028,8.145 12.028,8.483 L12.028,12.639 L12.028,12.639 Z M14.031,7.062 L12.937,7.062 L12.937,5.968 L14.031,5.968 L14.031,7.062 L14.031,7.062 Z"
                    fill={color} className="si-glyph-fill"></path>
            </g>
        </svg>
    )
}
export default Basket

Basket.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    openBasket: PropTypes.func
}