import Button from "../Button";
import "./index.scss";
import {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const Modal = ({modalClass, clickCloseModalOut, btnClassA, btnClassC, btnText, clickCloseModal}) => {

    const select = useSelector(state => state.my.openModal);

    const modal = useRef(null)

    useEffect(_ => {
        modal.current.animate([
            {transform: "translateY(-110%)"},
            {transform: "translateY(0%) "},
        ], {duration: 600, iterations: 1});
    })

    return (
        <div ref={modal} className={modalClass} onClick={clickCloseModalOut}>
            <div className="modal-window">
                <div className="modal-header">
                    <div className="modal-title">{select.modalTitleText}</div>
                    {
                        select.modalBtnCloseActive &&
                        <Button
                            btnClass={btnClassC}
                            functionOnClick={clickCloseModal}
                        />
                    }
                </div>
                <div className="modal-body">
                    <div className="modal-content">{select.modalContentText}</div>
                    <div className="modal-button">
                        {
                            <Button
                                btnClass={btnClassA}
                                btnText={btnText}
                                functionOnClick={select.functionOnClick}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    modalBtnCloseActive: PropTypes.bool,
    modalClass: PropTypes.string,
    clickCloseModalOut: PropTypes.func,
    modalTitleText: PropTypes.string,
    clickCloseModal: PropTypes.func,
    modalContentText: PropTypes.string,
};

Modal.defaultProps = {
    btnClassC: "btn-c",
    btnClassA: "btn-a",
    btnText: "Ok"
};
export default Modal