import "./index.scss"
import Stand from "../Stand";
import {useEffect, useContext} from "react";
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import {useDispatch, useSelector} from "react-redux";
import {loadStands, setCounterFavorite} from "../../my-storage/mySlice";
import TableStand from "./TableStand";
import ToggleContext from "../../my-context/toggle-context"
import CurrencyUnit from "../../my-context/currency-unit"

const Guns = ({addToCart}) => {
    const toggle = useSelector(state => state.my.toggle);
    const dispatch = useDispatch()
    useEffect(() => {dispatch(loadStands())}, [dispatch]);
    const {setToggle} = useContext(ToggleContext)
    const currencyUnit = useContext(CurrencyUnit)
    const stands = useSelector(state => state.my.stands.arr);
    const status = useSelector(state => state.my.stands.status);
    const err = useSelector(state => state.my.stands.err);
    const setFavorite = obj => {dispatch(setCounterFavorite(obj))}
    const color = useSelector((state) => state.my.counterFavorite)
    const getColorStar = id => color.filter(_ => +_?.article === +id)[0]?.starColor === "red" && "red"

    if (err) {

        return <p className='err'>{err.message}</p>

    } else if (status === 'pending') {

        return (
            <div className="my-spinner">
                <Spinner animation="grow"/>
            </div>
        )

    } else

        return (
            <>
                <main className="main">
                    <p onClick={_=>dispatch(setToggle())} className="main--trek">Guns & Rifle</p>
                    <div className={toggle ? "main--case" : "main--table-case"}>
                        {
                            toggle ? stands.map(stand => (
                                <Stand
                                    stand={stand}
                                    key={stand.article}
                                    starColor={getColorStar(stand.article) || "black"}
                                    currencyUnit={currencyUnit}
                                    addToCart={_ => addToCart(stand)}
                                    setFavorite={_ => setFavorite(stand)}
                                />))
                            : <TableStand
                                    stands={stands}
                                    getColorStar={getColorStar}
                                    currencyUnit={currencyUnit}
                                    addToCart={addToCart}
                                    setFavorite={setFavorite}
                                />
                        }
                    </div>
                </main>
            </>
        )
}
export default Guns

Guns.propTypes = {
    addToCart: PropTypes.func
}