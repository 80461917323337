import Star from "../Svg/Star";
import Button from "../Button";
import "./index.scss";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {openStand} from "../../my-storage/mySlice";

const Stand = ({
                   stand,
                   setFavorite,
                   addToCart,
                   currencyUnit,
                   starColor,
               }) => {
    const dispatch = useDispatch()
    const startBigStand = stand => {
        dispatch(openStand(stand))
    };
    return (
        <div className="stand">
            <img src={stand.src} className="stand--img-top" alt={stand.alt}/>
            <div className="stand--body">
                <div className="stand--stance">
                    <h5 className="stand--title">
                        {stand.cardTitle}
                    </h5>
                    <p className="stand--star">
                        <small>
                            favorites:
                        </small>
                        <Star
                            color={starColor}
                            width="1.5rem"
                            height="1.5rem"
                            setFavorite={setFavorite}
                        />
                    </p>
                </div>
                <p className="stand--stance">
                    {stand.description}
                </p>
                <p className="stand--stance">
                    <small className="stand--text-muted">
                        Article: {stand.article}
                    </small>
                </p>
                <div className="stand--button">
                            <span className="stand--price">
                                {currencyUnit} {stand.price}
                            </span>
                    <div className="stand--button-add">
                        <NavLink to={`/guns/${stand.article}`}>
                            <Button
                                btnClass="btn-a"
                                btnText="Watch more"
                                functionOnClick={_=>startBigStand(stand)}
                            />
                        </NavLink>
                    </div>
                    <Button
                        functionOnClick={addToCart}
                        btnText="Add to cart"
                        btnClass="btn-d"
                    />
                </div>
            </div>
        </div>
    )
}
export default Stand

Stand.propTypes = {
    stand: PropTypes.object,
    setFavorite: PropTypes.func,
    addToCart: PropTypes.func,
    watchMore: PropTypes.func,
    starColor: PropTypes.string
};