import Explora from "../Svg/Explora";
import Infinity from "../Svg/Infinity";
import "./index.scss";
import {Link} from "react-router-dom";

const Home = () => (
    <div className="wrap">
        <p className="trek main--trek"><Link to="/guns">Westley Richards</Link></p>
        <p className="wrap--excuse">
            This site is not commercial. The materials were taken from the site <a
            href="https://www.westleyrichards.com/">Westley Richards</a>
        </p>
        <div className="item-zer">
            <div className="item-zer--wr-img">
                <img src="/img/home/homepage2.jpg" alt=""/>
            </div>
        </div>
        <div className="item-one">
            <div className="item-one--wr-img">
                <img src="/img/home/homepage1.jpg" alt=""/>
            </div>
            <div className="item-one--wr-co">
                <div className="item-one--wr-co--svg">
                    <Explora/>
                </div>
                <p>
                    In 1812, William Westley Richards founded the company that bears his name and continues to flourish
                    today. In over 200 years of continuous gunmaking, Westley Richards has been credited with some of
                    the greatest designs in both military and sporting firearm history
                </p>
            </div>
        </div>
        <div className="item-one">

            <div className="item-one--wr-co">
                <div className="item-one--wr-co--svg">
                    <Infinity/>
                </div>
                <p>
                    For those customers who are especially fond of having their sporting guns more ornately engraved, we
                    have found the sidelock shotgun provides the largest canvas for this. The sleek lines of our round
                    body gun only adds beauty, and we take great pleasure in collaborating with the client to draft that
                    unique, once-in-a-lifetime design, and then utilising one of our specialist engravers to execute the
                    design, the results we feel are always spectacular.
                </p>
            </div>
            <div className="item-one--wr-img">
                <img src="/img/home/homepage3.jpg" alt=""/>
            </div>
        </div>
    </div>
)

export default Home