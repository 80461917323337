import "./index.scss"
import Table from 'react-bootstrap/Table';
import Button from "../Button";
import {useDispatch, useSelector} from "react-redux";
import {activeModal, deleteFavorite, deleteProduct, setOpenModal, setCounterProduct} from "../../my-storage/mySlice";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

const YourSelect = ({closeModal, mySelect}) => {
    const navigate = useNavigate();
    const standsFavorite = useSelector(state => state.my.counterFavorite)
    const standsProduct = useSelector(state => state.my.counterProduct)
    const stands = mySelect.select === 'favorite' ? standsFavorite : standsProduct
    const dispatch = useDispatch()
    const addToCart = obj => dispatch(setCounterProduct({obj}))
    const setActiveModal = bool => dispatch(activeModal({bool}))
    const clickOpenModal = stand => {
        dispatch(setOpenModal({
            modalTitleText: mySelect.modalTitleText,
            modalContentText: mySelect.modalContentText,
            modalBtnCloseActive: true,
            functionOnClick: () => {
                setTimeout(_ => {
                    mySelect.select === 'favorite' ?
                        dispatch(deleteFavorite(stand.article)) :
                        dispatch(deleteProduct(stand.article))
                }, 500)
                closeModal()
            }
        }))
        setActiveModal(true)
    }

    return (
        <div className="my-table">
            <p className="main--trek">Your {mySelect.select}</p>
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>article</th>
                    <th>img</th>
                    <th>cardTitle</th>
                    <th>price</th>
                    <th>action</th>
                </tr>
                </thead>
                <tbody>
                {stands.map(stand => (
                    <tr key={stand.article}>
                        <td className="td td-article">{stand.article}</td>
                        <td className="td td-img"><img className="td-img--pic" src={stand.src} alt=""/></td>
                        <td className="td td-title">{stand.cardTitle}</td>
                        <td className="td td-price">{stand.price}</td>
                        <td className="td td-delete">
                            <Button
                                btnText={"delete"}
                                btnClass="btn-d"
                                functionOnClick={_ => clickOpenModal(stand)}
                            />
                            <Button
                                btnText={mySelect.activeBtn}
                                functionOnClick={_ =>
                                    mySelect.select === 'favorite' ? addToCart(stand) : navigate("/order", {state: [stand]})
                                }
                                btnClass="btn-d"
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                {
                    mySelect.select !== 'favorite' && stands.length &&
                    <tr>
                        <td className="td" colSpan="5">
                            <Button
                                btnText="place an orderAll"
                                functionOnClick={_ =>navigate("/order", {state: stands})}
                                btnClass="btn-d btn-width-50"
                            />
                        </td>
                    </tr>
                }
                </tfoot>
            </Table>
        </div>
    )
}

YourSelect.propTypes = {
    mySelect: PropTypes.object,
    closeModal: PropTypes.func,
}
export default YourSelect