import "./index.scss";
import PropTypes from "prop-types";

const Button = ({btnClass, functionOnClick, btnText, type}) => (
    <button className={btnClass}
            onClick={functionOnClick}
            type={type}
    >{btnText}</button>
)

Button.propTypes = {
    btnText: PropTypes.string,
    functionOnClick: PropTypes.func,
    btnClass: PropTypes.string
};

export default Button