import "./index.scss"
import Basket from "../Svg/Basket";
import Star from "../Svg/Star";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {clearCounter} from "../../my-storage/mySlice";

const NavBar = ({color, size}) => {
    const dispatch = useDispatch()
    const counterFavorite = useSelector(state => state.my.counterFavorite.length);
    const counterProduct = useSelector(state => state.my.counterProduct.length);
    const clearSelect = _ => dispatch(clearCounter())

    return (
        <nav className="navbar navbar-my-bg  transparent fixed-top">
            <nav className="navbar navbar-expand-lg container-fluid">
                <Link className="navbar-brand" to="/">Guns & Rifles</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="/" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                  aria-expanded="false">Category</Link>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link className="nav-link" to="/guns">Guns</Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/accessories">Accessories</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="/" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                  aria-expanded="false">Service</Link>
                            <ul className="dropdown-menu">
                                <li>
                                    <span onClick={clearSelect}  className="dropdown-item">Clear Select</span>
                                </li>
                                <li>
                                    <Link to="/test" className="dropdown-item">Test state</Link>
                                </li>
                                <li>
                                    <Link to="/order" className="dropdown-item">Order test</Link>
                                </li>
                                <li>
                                    <Link to="/order/ok" className="dropdown-item">order/ok test</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className="navbar-my-icon">
                                <Link to="/cart">
                                    <Basket
                                        color={color}
                                        width={size}
                                        height={size}
                                    />
                                </Link>
                                <span className="counter-icon">{counterProduct}</span>
                                <Link to="/favorite">
                                    <Star
                                        color={color}
                                        width={size}
                                        height={size}
                                    />
                                </Link>
                                <span className="counter-icon">{counterFavorite}</span>
                            </div>
                        </li>
                    </ul>
                    <form className="d-flex search" role="search">
                        <input className="form-control me-2 input-search" type="search" placeholder="Search"
                               aria-label="Search"/>
                        <button className="btn btn-outline-secondary btn-search" type="submit">Search</button>
                    </form>
                </div>
            </nav>
        </nav>
    )
}

NavBar.propTypes = {
    clearFavorite: PropTypes.func,
    counterProduct: PropTypes.number,
    counterFavorite: PropTypes.number
}

NavBar.defaultProps = {
    color: "#fff",
    size: "1.5rem"
};

export default NavBar
