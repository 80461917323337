import {NavLink} from "react-router-dom";

const Index =()=>{
    return (<div className="order-wrap">
        <h3>Ok!</h3>
        <p>Your order has been received!</p>
        <p>Our managers are already processing it</p>
        <p>You can view other products while you are contacted for confirmation.</p>
        <p><NavLink to="/guns" className="btn-d btn-nav-link btn-width-50">Guns</NavLink></p>
        <p>If you got here for another reason, you can visit our main page</p>
        <p><NavLink to="/" className="btn-d btn-nav-link btn-width-50">Home</NavLink></p>
    </div>)
}

export default Index