const ModalCart =({counterOrder})=>{
    let total = 0
    return(
        <>
            <ul>
                {counterOrder.map(_ => <li key={_.article}>
                    <p>{_.name} {_.count} pieces {_.price} <span className="none">{total += _.price}</span></p>
                </li>)}
            </ul>
            <span>The amount to be paid:&nbsp;&nbsp;{total}</span>
            <span className="center">Place an order?</span>
        </>
    )
}

export default ModalCart