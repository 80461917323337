import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

export const loadStands = createAsyncThunk(
    'my/loadStands',
    async (_, {rejectWithValue}) => {
        try {
            const res = await fetch('/source/source1.html')
            return await res.json();
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

export const defaultInitialState = {
    toggle: false,
    actModal: false,
    openModal: {},
    counterFavorite: [],
    counterProduct: [],
    stands: {
        arr: [],
        status: '',
        err: '',
    },
    stand: {
        stand: {},
        boolStn: true
    },
    order:{
        counterOrder: [],
    }
}

const mySlice = createSlice({
    name: 'my',
    initialState: defaultInitialState,
    reducers: {
        setToggle(state){state.toggle = !state.toggle},

        clearCounterOrder(state){state.order.counterOrder = []},

        setCounterOrder(state, action) {
            if(!Array.isArray(action.payload)){
                state.order.counterOrder =  state.order.counterOrder.filter(_ => _.article !== action.payload.article)
            }else if(Boolean(action.payload[0])){
                state.order.counterOrder = action.payload.map(_=>{
                    const a = {}
                    a.name = _.cardTitle
                    a.article = _.article;
                    a.price = Number(_.price.replace(' ', ''));
                    a.count = 1
                    return a
                })
            }
        },

        increase(state, action) {
            state.order.counterOrder.forEach(_=> {
                if(_.article === action.payload.article) {
                    _.count = _.count + 1
                    _.price = _.price + Number(action.payload.price.replace(' ', ''))
                }
            })
        },

        decrease(state, action) {
            if(Boolean(action.payload.count.count - 1)){
                state.order.counterOrder.forEach(_=> {
                    if(_.article === action.payload.obj.article) {
                        _.count = _.count - 1
                        _.price = _.price - Number(action.payload.obj.price.replace(' ', ''))
                    }
                })
            } else {
                state.counterProduct = state.counterProduct.filter(_ => _.article !== action.payload.obj.article)
            }
        },

        setCounterFavorite(state, action) {
            let item
            if (!state.counterFavorite.find(_ => _.article === action.payload.article)) {
                state.counterFavorite.push({...action.payload, starColor: "red"})
            } else {
                state.counterFavorite = state.counterFavorite.map((e,i)=> {
                    if (e.article === action.payload.article){
                        item = i + 1
                        return  {...e, starColor: "black"}
                    } else {
                        return  e
                    }
                })
                item && state.counterFavorite.splice(item - 1, 1)
            }
        },

        deleteFavorite(state, action) {
            state.counterFavorite = state.counterFavorite.filter(_ => _.article !== action.payload)
        },

        setCounterProduct(state, action) {
            if (!state.counterProduct.find(_ => _.article === action.payload.obj.article)) {
                state.counterProduct.push(action.payload.obj)
            }
        },

        deleteProduct(state, action) {
            state.counterProduct = state.counterProduct.filter(_ => _.article !== action.payload)
        },

        clearCounter(state) {
            state.counterProduct = []
            state.counterFavorite = []
        },

        activeModal(state, action) {state.actModal = action.payload.bool},

        setOpenModal(state, action) {
            state.openModal = {
                modalTitleText: action.payload.modalTitleText,
                modalContentText: action.payload.modalContentText,
                modalBtnCloseActive: action.payload.modalBtnCloseActive,
                functionOnClick: action.payload.functionOnClick
            }
        },

        setCloseModal(state) {state.openModal = {}},

        openStand(state, action) {state.stand = action.payload},

        closeStand(state){state.stand = {}},

//VVV*testCreateAsyncThunk*VVV
        clearAll(state) {
            state.stands.arr = []
            state.stands.err = ''
            state.stands.status = ''
            state.stand = {}
            state.counterProduct = []
            state.counterFavorite = []
        },

        clearArr(state) {
            state.stands.arr = []
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadStands.pending, state => {
                state.stands.status = 'pending'
            })
            .addCase(loadStands.fulfilled, (state, action) => {
                state.stands.arr = action.payload
                state.stands.status = 'fulfilled'
            })
            .addCase(loadStands.rejected, (state, action) => {
                state.stands.err = action.error
                state.stands.status = 'fulfilled'
            })
    }
})

export const {
    activeModal,
    setOpenModal,
    setCounterFavorite,
    setCounterProduct,
    clearCounter,
    deleteFavorite,
    deleteProduct,
    setCloseModal,
    openStand,
    closeStand,
    setCounterOrder,
    clearCounterOrder,
    increase,
    decrease,
    setToggle,
/** VVV test VVV **/
    clearAll,
    clearArr,
} = mySlice.actions

export default mySlice.reducer