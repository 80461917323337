import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import store from './my-storage';
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import CurrencyUnit from "./my-context/currency-unit"

const persist = persistStore(store);

ReactDOM
    .createRoot(document.getElementById('root'))
    .render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <PersistGate loading={null} persistor={persist}>
                        <CurrencyUnit.Provider value={"£"}>
                            <App/>
                        </CurrencyUnit.Provider>
                    </PersistGate>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );