import {Route, Routes} from "react-router-dom";
import {useContext, useRef} from "react";
import PropTypes from "prop-types";
import {closeAnimate} from "./my-function"
import "./App.scss";

import Home from "./components/Home";
import Modal from "./components/Modal";
import Guns from "./components/Guns";
import YourSelect from "./components/YourSelect";
import NoteFound from "./components/NoteFound";
import Layout from "./components/Layout";
import LayoutOrder from "./components/Layout/LayoutOrder";
import Order from "./components/Order";
import Ok from "./components/Ok";
import BigStand from "./components/BigStand";

import ToggleContext from "./my-context/toggle-context";
import CurrencyUnit from "./my-context/currency-unit";
import Test from "./my-storage/testCreateAsyncThunk/test";

import {useDispatch, useSelector} from "react-redux";
import {activeModal, setOpenModal, setCounterProduct, setCloseModal, setToggle} from "./my-storage/mySlice";


const App = ({modalClass, modSetProduct, mySelect}) => {
    const dispatch = useDispatch()
    const active = useSelector(state => state.my.actModal);
    // const modal = useRef()
    // console.log(modal)
    const currencyUnit = useContext(CurrencyUnit)
    const setActiveModal = (bool) => dispatch(activeModal({bool}));
    const closeModal = () => {
        closeAnimate(modalClass)
        setTimeout(_ => {
            setActiveModal(false)
            dispatch(setCloseModal())
        }, 550)
    }

    const setProduct = obj => setTimeout(_ => {
        dispatch(setCounterProduct({obj}))
    }, 550)

    const clickOpenModal = obj => {
        dispatch(setOpenModal({
            modalTitleText: modSetProduct.modalTitleText,
            modalContentText: modSetProduct.modalContentText,
            modalBtnCloseActive: true,
            functionOnClick: () => {
                setProduct(obj)
                closeModal()
            }
        }))
        setActiveModal(true)
    }

    const clickCloseModalOut = _ => {
        if (_.target.classList.contains(modalClass)) {
            closeModal()
        }
    }

    return (
        <div className="App">
            {
                active && <Modal
                    modalClass={modalClass}
                    clickCloseModalOut={clickCloseModalOut}
                    clickCloseModal={closeModal}
                />
            }
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/guns"
                           element={
                               <ToggleContext.Provider value={{setToggle}}>
                                   <Guns addToCart={obj => clickOpenModal(obj)}/>
                               </ToggleContext.Provider>
                           }
                    />
                    <Route path="/:category/:article"
                           element={
                               <BigStand
                                   addToCart={obj => clickOpenModal(obj)}
                                   currencyUnit={currencyUnit}
                               />
                           }
                    />
                    <Route path="/cart"
                           element={
                               <YourSelect mySelect={mySelect.cart} closeModal={closeModal}/>
                           }
                    />
                    <Route path="/favorite"
                           element={
                               <YourSelect mySelect={mySelect.favorite} closeModal={closeModal}/>
                           }
                    />
                    <Route path="/test" element={<Test/>}/>
                    <Route path="*" element={<NoteFound/>}/>
                </Route>
                <Route path="/order"
                       element={<LayoutOrder/>}>
                    <Route index element={<Order closeModal={closeModal}/>}/>
                    <Route path="/order/ok" element={<Ok/>}/>
                </Route>
            </Routes>
        </div>
    )
};

App.propTypes = {
    modalClass: PropTypes.string,
    modalTitleText: PropTypes.string,
    height: PropTypes.string,
    openBasket: PropTypes.func,
    modSetProduct: PropTypes.object,
    mySelect: PropTypes.object
}

App.defaultProps = {
    modalClass: "my-modal",
    modSetProduct: {
        modalTitleText: 'Add to Shopping Cart?',
        modalContentText: 'You can add to cart or close window and add to favorites. Continue?',
    },
    mySelect: {
        favorite: {
            select: 'favorite',
            modalTitleText: 'Delete favorite',
            modalContentText: 'Are you sure you want to remove this item from your favorites?',
            activeBtn: "Add to cart"
        },
        cart: {
            select: 'shopping cart',
            modalTitleText: 'Delete item',
            modalContentText: 'Are you sure you want to delete the selected item?',
            activeBtn: "place an order"
        }
    }
};

export default App