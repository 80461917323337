import Button from "../Button";
import ModalCart from "./ModalCart"

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { Formik } from 'formik';
import * as yup from 'yup';
import { PatternFormat } from 'react-number-format';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {activeModal, clearCounterOrder, setOpenModal, deleteProduct} from "../../my-storage/mySlice";

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    terms: yup.bool().required().oneOf([true], 'terms must be accepted'),
    tel: yup.string().required().matches(/^(?!\+.*\(.*\).*--.*$)(?!\+.*\(.*\).*-$)(\+[0-9]{2}\([0-9]{3}\)[0-9]{3} [0-9]{2} [0-9]{2})$/ , 'please enter an existing number'),
    age:yup.string().required().matches(/^(?!0.*$)([0-9]{2})$/ , 'age cannot start with 0'),
});

function MyForm({counterOrder, closeModal}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const setActiveModal = bool => dispatch(activeModal({bool}))

    const clickOpenModal = user => {
        dispatch(setOpenModal({
            modalTitleText: 'Ready to ship',
            modalContentText:<ModalCart counterOrder={counterOrder}/>,//у модалку для підтвердження вивантажуємо список
            modalBtnCloseActive: true,
            functionOnClick: () => {
                setTimeout(_ => {
                    clearCounterOrder()
                    const arrFetch =[{order: {...counterOrder}, user: {...user}}] //створюємо узагальнений масив для надсилання на сервер
                    console.log(arrFetch)//сюди ми можемо замість консолі встановити асинхронну функцію для відправлення нашого масиву з єдиним об'єктом
                    counterOrder.forEach(_=>dispatch(deleteProduct(_.article))) //Видаляємо відправлені товари з кошика.Тут же можемо
                    // встановити умову при якій видалення не відбудеться, наприклад, у разі помилки відправки
                    navigate("/order/ok", {state: true})//переадресовуємо на сторінку результату, де другим параметром в
                    // стейт можемо передати помилку з эктраредьюсера асинхронної функції.
                }, 500)
                closeModal()
            }
        }))
        setActiveModal(true)
    }

    return (
        <Formik
            validationSchema={schema}
           onSubmit={user=> {
               clickOpenModal(user)
           }}
            initialValues={{
                firstName: 'Mark',
                lastName: 'Otto',
                city: '',
                state: '',
                terms: false,
                tel: 0,
                age: 0,
            }}
        >
            {({handleSubmit, handleChange,values,touched,errors,}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationFormik101" className="position-relative">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                className="pattern-format"
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                isValid={touched.firstName && !errors.firstName}
                            />
                            <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="validationFormik102" className="position-relative">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                className="pattern-format"
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                isValid={touched.lastName && !errors.lastName}
                            />
                            <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4">
                            <Form.Label>{errors.tel?<span className="form-errors">{errors.tel}</span> : "Telephone"}</Form.Label>
                            <InputGroup hasValidation>
                                    <PatternFormat
                                        allowEmptyFormatting mask="_"
                                        format="+38(###)### ## ##"
                                        className="pattern-format pattern-format--width"
                                        id="tel"
                                        value={values.tel}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationFormik103" className="position-relative">
                            <Form.Label>{errors.city?<span className="form-errors">{errors.city}</span> : "City"}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="City"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                className="pattern-format"
                            />
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationFormik104"
                            className="position-relative"
                        >
                            <Form.Label>{errors.state?<span className="form-errors">{errors.state}</span> : "State"}</Form.Label>
                            <Form.Control
                                className="pattern-format"
                                type="text"
                                placeholder="State"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="3" className="position-relative">
                            <Form.Label>{errors.age?<span className="form-errors">{errors.age}</span> : "Age"}</Form.Label>
                            <InputGroup hasValidation>
                            <PatternFormat
                                className="pattern-format pattern-format--width"
                                format="##"
                                id="age"
                                allowEmptyFormatting mask="_"
                                onChange={handleChange}
                                value={values.age}
                            />
                                </InputGroup>
                        </Form.Group>
                    </Row>

                    <Form.Group className="position-relative mb-3">
                        <Form.Check
                            required
                            name="terms"
                            label="Agree to terms and conditions"
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            feedbackType="invalid"
                            id="validationFormik106"
                            feedbackTooltip
                        />
                    </Form.Group>

                    <Button type="submit" btnClass="btn-d form-btn btn-width-50" btnText={"Checkout"}/>

                </Form>
            )}
        </Formik>
    );
}

export default MyForm