import "./index.scss"
import Sprite from "../Svg/Sprite";

const Footer =({tel, mailto})=> (
    <footer className="footer container-fluid">
        <h2 className="footer-title">- get in touch with us -</h2>
        <p>Maecenas faucibus molli interdum. Cras mattis consectetur purus sitor amet sed donec malesuada
            ullamcorper odio.
            <span className="item-big">Curabitur blandit tempus porttitor vollisky inceptos mollisestor.</span></p>
        <p className="sprite">
            <Sprite/>
        </p>
        <div className="contacts">
            <span>Moonshine St. 14-05 Light City&nbsp;&nbsp;&nbsp;/</span>
            <span><a href={tel}>{tel}</a>&nbsp;&nbsp;&nbsp;/</span>
            <span><a href={mailto}>{mailto}</a></span>
        </div>
    </footer>
)

Footer.defaultProps = {
    tel:"tel:+00 (123) 456 78 90",
    mailto:"mailto:first.last@email.com"
};

export default Footer