import "./index.scss"
import Star from "../Svg/Star";
import Button from "../Button";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {setCounterFavorite, closeStand} from "../../my-storage/mySlice";
import {NavLink, useParams} from "react-router-dom";

const BigStand = ({addToCart,currencyUnit}) => {
    const color = useSelector((state) => state.my.counterFavorite)
    const getColorStar = id => color.filter(_ => _.article === id)[0]?.starColor === "red" && "red"
    const dispatch = useDispatch()
    const{article} = useParams()
    const stand = useSelector(state => state.my.stands.arr.find(_=>_.article === article));

    return (<>
            <p className="main--trek">Best Guns & Rifles</p>
            <div className="bigStand">
                <img src={stand.src} className="bigStand--img-top" alt={stand.alt}/>
                <div className="bigStand--body">
                    <div className="bigStand--stance-title">
                        <h5 className="bigStand--title">
                            {stand.cardTitle}
                        </h5>
                        <p className="bigStand--stance-elect">
                            <small>
                                favorites:
                            </small>
                            <Star
                                color={getColorStar(stand.article) || "black"}
                                width="3rem"
                                height="3rem"
                                setFavorite={_=>dispatch(setCounterFavorite(stand))}
                            />
                        </p>
                    </div>
                    <p className="bigStand--stance">
                        {stand.description}
                    </p>
                    <p className="bigStand--stance">
                        <small className="bigStand--text-muted">
                            Article: {stand.article}
                        </small>
                    </p>
                    <div className="bigStand--button">
                            <span className="bigStand--price">
                                {currencyUnit} {stand.price}
                            </span>
                        <div className="bigStand--button--btn">
                            <Button functionOnClick={_=>addToCart(stand)}
                                    btnClass="btn-a"
                                    btnText="Add to cart"/>
                        </div>
                        <div className="bigStand--button--btn">
                            <NavLink to={`/guns`} onClick={_=>dispatch(closeStand())} className="btn-a btn-nav-link">Come back</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BigStand

BigStand.propTypes = {
    currencyUnit: PropTypes.string,
};