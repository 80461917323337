import "./star.scss"
import PropTypes from "prop-types";
const Star =({ color, width, height, setFavorite })=> {
    return (
        <svg className="icon-star" onClick={setFavorite} viewBox="0 0 32 32" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="m0 0h32v32h-32z" />
                <path
                    d="m15.5398797 25.5859757-7.63729157 3.957929c-.49034963.2541174-1.09385945.0626134-1.3479769-.4277363-.10064257-.1942017-.13483627-.4160451-.0973418-.6315385l1.45155972-8.3426061c.05700026-.3275998-.05256879-.6621042-.29234213-.8924939l-6.13832708-5.8981019c-.39823914-.3826539-.41087345-1.0156928-.02821951-1.413932.15455902-.1608541.35846426-.2654106.57928565-.2970405l8.50841362-1.2187218c.3245128-.0464823.6056348-.2490693.7523987-.54220671l3.8157728-7.62139263c.2472523-.49384685.848032-.69375117 1.3418788-.44649881.1931499.09670361.3497952.2533489.4464988.44649881l3.8157728 7.62139263c.1467639.29313741.4278859.49572441.7523987.54220671l8.5084136 1.2187218c.5467049.0783085.9264152.584982.8481067 1.1316869-.0316299.2208213-.1361864.4247266-.2970405.5792856l-6.1383271 5.8981019c-.2397734.2303897-.3493424.5648941-.2923421.8924939l1.4515597 8.3426061c.0946716.5441101-.2696702 1.0619449-.8137802 1.1566166-.2154934.0374944-.4373368.0033007-.6315385-.0973418l-7.6372916-3.957929c-.2885253-.1495246-.6317153-.1495246-.9202406 0z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
export default Star

Star.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    setFavorite: PropTypes.func
}