import "./index.scss"
import NavBar from '../NavBar'
import Carousel from 'react-bootstrap/Carousel';
import PropTypes from "prop-types";

const Header =()=> {
    return(
        <header className="header">
            <NavBar/>
            <Carousel fade >
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/header/all_desktop_2.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>Apparel&Accessories</h3>
                        <p>Whether armed with rifle or camera and bound for high veldt or delta, our rugged, easy-care, comfortable, safari garments for the coming season deliver the perfect balance of style and strength. </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/header/all_desktop_3.jpg"
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <h3>Best Guns & Rifles</h3>
                        <p>By ordering a bespoke handcrafted gun or rifle from Westley Richards, you are joining a patronage that includes some of the greatest hunters and sportsmen the world has ever seen.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/header/all_desktop_5.jpg"
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Gun & Rifle Engraving</h3>
                        <p>
                            We are privileged today to work with and commission some of the finest engravers in the world, many of them exclusively.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </header>
    )
}


Header.propTypes = {
    clearFavorite: PropTypes.func,
    counterProduct: PropTypes.number,
    counterFavorite: PropTypes.number
};

export default Header
