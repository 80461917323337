import Table from 'react-bootstrap/Table';
import Button from "../Button";
import Star from "../Svg/Star";
import "./index.scss";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {openStand} from "../../my-storage/mySlice";
const TableStand = ({
                   stands,
                   setFavorite,
                   addToCart,
                   currencyUnit,
                   getColorStar,
               }) => {

    const dispatch = useDispatch()
    const startBigStand = stand => {
        dispatch(openStand(stand))
    };
    return (
        <div className="my-table">
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>article</th>
                    <th>img</th>
                    <th>cardTitle</th>
                    <th>price</th>
                    <th>favorite</th>
                    <th>action</th>
                </tr>
                </thead>
                <tbody>
                {stands.map(stand => (
                    <tr key={stand.article}>
                        <td className="td td-article">{stand.article}</td>
                        <td className="td td-img"><img className="td-img--pic" src={stand.src} alt=""/></td>
                        <td className="td td-title">{stand.cardTitle}</td>
                        <td className="td td-price">{stand.price}{currencyUnit}</td>
                        <td className="td td-star">{
                            <Star
                                color={getColorStar(stand.article) || "black"}
                                setFavorite={_ => setFavorite(stand)}
                                width="1.5rem"
                                height="1.5rem"
                            />
                        }</td>
                        <td className="td td-delete">
                            <NavLink to={`/guns/${stand.article}`}>
                                <Button
                                    btnClass="btn-a"
                                    btnText="Watch more"
                                    functionOnClick={_=>startBigStand(stand)}
                                />
                            </NavLink>
                            <Button
                                functionOnClick={_ => addToCart(stand)}
                                btnText="Add to cart"
                                btnClass="btn-d"
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    )
}
export default TableStand

Table.propTypes = {
    stand: PropTypes.object,
    setFavorite: PropTypes.func,
    addToCart: PropTypes.func,
    watchMore: PropTypes.func,
    currencyUnit: PropTypes.string,
    starColor: PropTypes.string
};