import "./index.scss"
import {Link} from "react-router-dom";
const NoteFounde = () => {
    return (
        <>
            <div className="wrp">
                <p>This page doesn't exist. Go <Link to="/">home</Link></p>
                <h1>404</h1>
            </div>
        </>
    )
}
export default NoteFounde