import { useDispatch, useSelector } from "react-redux"
import {useEffect} from "react";
import {loadStands} from '../mySlice'
import {Li} from './Li'
import {clearAll,clearArr, clearCounter} from "../mySlice";
import "./index.scss";
export default function Test() {
    const dispatch = useDispatch();

    const status = useSelector(state => state.my.stands.status)
    const arr = useSelector(state => state.my.stands.arr);
    const err = useSelector(state => state.my.stands.err);


    useEffect(() => {
        dispatch(loadStands());
    }, [dispatch]);

    if (err) {
        return <p className='err'>{err.message}</p>
    } else {
        if (status === 'pending') {
            return <p>..........................</p>;
        } else {
            return (
                <div className='test'>
                    <button onClick={_=>dispatch(clearAll())}>Clear All</button>
                    <button onClick={_=>dispatch(clearArr())}>Clear Stands</button>
                    <button onClick={_=>dispatch(loadStands())}>Load Stands</button>
                    <button onClick={_=>dispatch(clearCounter())}>Clear Select</button>
                    <p className='err'>
                        status:{status}
                    </p>
                    <p className='err'>
                        err:{err}
                    </p>
                    <ul>
                        {
                            arr.map(item => <Li _={item} key={item.article}/>)
                        }
                    </ul>
                </div>
            );
        }
    }
}