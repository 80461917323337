import{configureStore,  combineReducers} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';

import myReducer from './mySlice'; // mySlice.reducer

const rootReducer = combineReducers({
    my: myReducer,
});

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export const setupStore = preloadedState => {
    return configureStore({
        reducer:  rootReducer,
        preloadedState
    })
}

export default store;

